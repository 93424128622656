.containeradm {
    padding: 3rem 0;
}
.admin-form{
    padding: 0.6rem 0.6rem;
}
.admin-form-elem{
    margin-left: auto;
    margin-right: auto;
    background-color: var(--yellow-green-color);
    margin-bottom: 0.5rem;
    text-align: left;
    box-shadow: rgba(0, 70, 0, 0.9) 6px 6px 11px -3px;
    padding: 0.6rem 0.6rem;
    border-radius: 0.7rem;
    max-width: 25rem;
}
.admin-form-elem .form-control{
    background-color: var(--grey-color);
    font-size: 1rem;
    padding: 0.6rem 0.6rem;
}

.admin-form-elem .message-control{
    background-color: var(--grey-color);
    width:100%;
    min-height: 20rem;
    font-size: 1rem;
    padding: 0.2rem 0.3rem;
}

.admin-form-button {
    display: block;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 15rem;
    padding: 0.9rem;
    box-shadow: rgba(0, 50, 0, 0.9) 8px 8px 12px -3px;
    color: var(--special-green-color);
    font-size: 1.6rem;
    background-color: green;
    border-radius: 10px;
}

.admin-form-elem .form-control::placeholder{
    opacity: 0.9;
}

.admin{
    padding: 3rem 0;
}
.admin-content{
    gap: 4rem;
}
.admin-title{
    text-align: center;
    text-decoration: underline;
    margin: 1rem 0;
    color: #ddfcb6;
}
.admin-img img{
    max-width: 520px;
}
.admin-text p{
    margin: 1.8rem 0;
    opacity: 0.8;
    color: #ddfcb6;
}

.admin-text a{
    color: blue;
    background-color: greenyellow;
}

.admin-text h2{
    margin: 1rem 0;
    color: #ddfcb6;
}

.noAuth{
    text-align: center;
    margin: 1rem 0;
    color: red;
}

.hola-salir{
    text-align: right;
    margin: 1rem 0;
    color: #ddfcb6;
}

.lnk-salir{
    color: #ddfcb6;
}

.bton-salir{
    text-align: right;
    padding: 0.3rem;
    margin: 0.2rem 0;
    box-shadow: rgba(0, 50, 0, 0.9) 8px 8px 12px -3px;
    color: var(--special-green-color);
    background-color: green;
    border-radius: 10px;
}

.bton-tab{
    text-align: center;
    padding: 0.6rem;
    margin: 0.4rem 0;
    box-shadow: rgba(0, 50, 0, 0.9) 8px 8px 12px -3px;
    color: var(--special-green-color);
    background-color: green;
    border-radius: 10px;
}
.bton-tab.yellow{
    text-align: center;
    padding: 0.6rem;
    margin: 0.4rem 0;
    box-shadow: rgba(0, 50, 0, 0.9) 8px 8px 12px -3px;
    color: yellow;
    background-color: green;
    border-radius: 10px;
}

.uls {
    text-align: center;
}

.uls ul {
    list-style-type: none;
    display: inline;
}

.uls li {
    display: inline;
    margin: 5px;
    padding: 0.4rem;
}

.uls li:hover {
    cursor: pointer;
}

.show {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

.hide {
    display: none;
}