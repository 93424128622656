.amigos{
    padding: 0.3rem;
    box-shadow: rgba(0, 50, 0, 0.9) 8px 8px 12px -3px;
    background-color: #ddfcb6;
    margin: 8px;
    border-radius: 15px;
}

.amigos-collapse{
    padding: 2px;
}

.amigos-item{
    margin-bottom: 0.3rem;
}
.amigos-item img{
    display: block;
    margin: auto;
    max-width: 20rem;
}

.amigos-link{
    color: var(--purple-color);
    font-size: 1.2rem;
    text-decoration: underline;
}

.amigos-link:hover{
    color: var(--special-green-color);
}

.amigos-nav{
    margin-top: 1px;
}

.amigos-subtitle {
    text-align: center;
    background-color: green;
    border-radius: 15px;
}

.amigos-subtitle span {
    margin-left: 1rem;
    color: #00fb76;
}

