.containersf {
    padding: 0.1rem 0.1rem;
}
.search-form{
    width: 100%;

    padding: 0.1rem 0.1rem;
    
    justify-content: center;
}
.search-form-elem{
    box-shadow: rgba(0, 70, 0, 0.9) 3px 3px 9px -3px;
    padding: 0.6rem 0.4rem;
    border-radius: 0.7rem;
}
.search-form-elem .form-control{
    width: 100%;
    font-size: 1rem;
    padding: 0.1rem;
}
.search-form-elem .form-control::placeholder{
    opacity: 0.9;
}