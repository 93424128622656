.links{
    text-align: center;
    padding: 0.5rem 0;
}
.links-content{
    gap: 4rem;
}
.links-title{
    margin: 1rem 0;
}
.links-img img{
    max-width: 520px;
}
.links-text p{
    margin: 1.8rem 0;
    opacity: 0.8;
    color: #ddfcb6;
}

.links-text a{
    font-weight: bold;
    color: yellow;
}

.links-text h2{
    text-align: center;
    text-decoration: underline;
    margin: 1rem 0;
    color: #ddfcb6;
}
.links-text h3{
    margin: 1rem 0;
    color: #ddfcb6;
}

@media screen and (min-width: 800px){
    .links-title{
        margin-top: 0;
    }
    .autor-img img{
        margin-left: 0;
    }
}