.containerct {
    padding: 1rem 0;
}
.contact-form{
    padding: 0.6rem 0.6rem;
}
.contact-form-elem{
    margin-left: auto;
    margin-right: auto;
    background-color: var(--yellow-green-color);
    margin-bottom: 0.5rem;
    text-align: left;
    box-shadow: rgba(0, 70, 0, 0.9) 6px 6px 11px -3px;
    padding: 0.6rem 0.6rem;
    border-radius: 0.7rem;
    max-width: 25rem;
}
.contact-form-elem .form-control{
    background-color: var(--grey-color);
    font-size: 1rem;
    padding: 0.6rem 0.6rem;
}

.contact-form-elem .message-control{
    background-color: var(--grey-color);
    width:100%;
    min-height: 20rem;
    font-size: 1rem;
    padding: 0.2rem 0.3rem;
}

.contact-form-button {
    display: block;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 15rem;
    padding: 0.9rem;
    box-shadow: rgba(0, 50, 0, 0.9) 8px 8px 12px -3px;
    color: var(--special-green-color);
    font-size: 1.6rem;
    background-color: green;
    border-radius: 10px;
}

.contact-form-elem .form-control::placeholder{
    opacity: 0.9;
}

.about{
    padding: 3rem 0;
}
.contact-content{
    gap: 4rem;
}
.about-title{
    margin: 0.5rem 0;
}
.about-img img{
    max-width: 520px;
}
.about-text p{
    margin: 1.8rem 0;
    opacity: 0.8;
    color: #ddfcb6;
}

.about-text a{
    color: blue;
    background-color: greenyellow;
}

.about-text h2{
    margin: 0.5rem 0;
    color: #ddfcb6;
}

