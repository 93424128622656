.inicio{
    padding: 0.5rem 0;
}

.inicio-content{
    gap: 4rem;
}

.inicio-title{
    margin: 1rem 0;
}

.inicio-img img{
    max-width: 520px;
}

.inicio-text p{
    margin: 1.8rem 0;
    opacity: 0.8;
    color: #ddfcb6;
}

.inicio-text a{
    font-weight: bold;
    color: blue;
    background-color: greenyellow;
}

.inicio-text h1{
    text-decoration: underline;
    text-align: left;
    font-size: 18px;
    margin: 1rem 0;
    color: #ddfcb6;
}

.inicio-text h2{
    text-align: center;
    text-decoration: underline;
    margin: 1rem 0;
    color: #ddfcb6;
}

@media screen and (min-width: 800px){
    .inicio-title{
        margin-top: 0;
    }
    .inicio-img img{
        margin-left: 0;
    }
}