.otros-details{
    padding: 0.5rem 0;
}
.back-btn{
    margin-left: 20px;
    margin-bottom: 2.6rem;
    transition: var(--transition);
    color: yellow;
}
.back-btn:hover{
    color: var(--special-green-color);
}
.back-btn span{
    margin-left: 1rem;
}
.otros-details-content{
    gap: 4rem;
}
.otros-details-img{
    max-height: 600px;
    overflow: visible;
}
.otros-details-img img{
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.otros-details-item{
    margin-bottom: 1.4rem;
}
.otros-details-item.description{
    opacity: 0.8;
}
.otros-details-info{
    border-radius: 14px;
    background-color: var(--yellow-green-color);
    box-shadow: rgba(0, 70, 0, 0.97) 0px 15px 25px 0px;
    overflow-y: scroll;
    max-height: 600px;
    padding: 1.4rem;
}
.otros-details-info::-webkit-scrollbar{
    width: 8px;
}
.otros-details-info::-webkit-scrollbar-track{
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.otros-details-info::-webkit-scrollbar-thumb{
    background-color: var(--purple-color);
    outline: -1px solid slategray;
    border-radius: 8px;
}

@media screen and (min-width: 768px){
    .otros-details-content{
        grid-template-columns: 40% 60%;
        gap: 0
    }
    .otros-details-img{
        padding: 0 6rem 0 2rem;
    }
    .otros-details-img img{
        max-width: 100%;
    }
}