.revistaslist, .revistas-details{
    padding: 3rem 1rem;
    background:#000000 url(../../images/backgrounds_03.png) repeat scroll 0 0;
}
.revistaslist-content{
    gap: 3rem;
}
.result {
    color: yellow;
}
.revistas-item{
    padding: 3rem 2rem;
    border-radius: 14px;
    background-color: var(--yellow-green-color);
    box-shadow: rgba(0, 50, 0, 0.7) 0px 15px 25px 0px;
    transition: var(--transition);
}
.revistas-item:hover{
    box-shadow: rgba(0, 50, 0, 1.0) 0px 25px 35px 0px;
}
.revistas-item-img img{
    max-width: 250px;
    margin-right: auto;
    margin-left: auto;
}
.revistas-item-info{
    margin-top: 2rem;
}
.revistas-item-info-item.title{
    line-height: 1.4;
    margin-bottom: 0.8rem;
}
.revistas-item-info-item.author{
    line-height: 1.4;
    margin-bottom: 4px;
}
.revistas-item-info-item:not(.revistas-item-info-item.title) span:last-child{
    opacity: 0.8;
}
.revistas-item-info-item.publish-year{
    opacity: 0.6;
    font-style: italic;
    font-size: 14px;
}

@media screen and (min-width: 600px){
    .revistaslist-content{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 992px){
    .revistaslist-content{
        grid-template-columns: repeat(3, 1fr);
    }
    .revistas-item-img img{
        max-width: 150px;
    }
}

@media screen and (min-width: 992px){
    .revistaslist-content{
        grid-template-columns: repeat(4, 1fr);
    }
    .revistas-item-img img{
        max-width: 120px;
    }
}