
/*bodysw {
  position: relative;
  height: 100%;
}*/

/*.bodysw {
  background: transparent;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}*/

.swiper {
  height: 400px;
  width: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  max-width: 300px;
  max-height: 400px;
}

.swiper-slide img {
  display: block;
  height: 390px;
}

.about-text h2{
    text-align: center;
    text-decoration: underline;
    margin: 1rem 0;
    color: #ddfcb6;
}

.about-title {
  margin: 1px;
}