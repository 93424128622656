.razones{
    padding: 0.5rem 0;
}
.razones-content{
    gap: 4rem;
}
.razones-title{
    margin: 1rem 0;
}
.razones-img img{
    max-width: 520px;
}
.razones-text p{
    text-align: justify;
    margin: 1.8rem 0;
    opacity: 0.8;
    color: #ddfcb6;
}

.razones-text a{
    font-weight: bold;
    color: blue;

}

.razones-text h1{
    text-decoration: underline;
    text-align: left;
    font-size: 18px;
    margin: 1rem 0;
    color: #ddfcb6;
}

.razones-text h2{
    text-align: center;
    text-decoration: underline;
    margin: 1rem 0;
    color: #ddfcb6;
}

@media screen and (min-width: 800px){
    .razones-title{
        margin-top: 0;
    }
    .razones-img img{
        margin-left: 0;
    }
}