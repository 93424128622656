.buscador{
    padding: 0.3rem;
    box-shadow: rgba(0, 50, 0, 0.9) 8px 8px 12px -3px;
    background-color: #ddfcb6;
    margin: 8px;
    border-radius: 15px;
}

.buscador-collapse{
    padding: 2px;
}

.buscador-item{
    text-align: center;
    margin-bottom: 0.3rem;
}

.buscador-link{
    color: var(--purple-color);
    font-size: 1.2rem;
    text-decoration: underline;
}

.buscador-link:hover{
    color: var(--special-green-color);
}

.buscador-nav{
    margin-top: 1px;
}

.buscador-subtitle {
    text-align: center;
    background-color: green;
    border-radius: 15px;
}

.buscador-subtitle span {
    margin-left: 1rem;
    color: #00fb76;
}

.center {
    text-align: center;
}

.us-form select {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    height: 2.2em;
    margin: 2px;
    padding: 0.3em 0.6em;
    background: white;
    border: 1px solid #aaaaaa;
    border-radius: 0.3em;
    box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.4);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.85em;
    font-weight: normal;
    overflow: hidden;
    text-shadow: 0 1px 0 white;
    -webkit-transition: box-shadow 200ms, border-color 200ms;
    -moz-transition: box-shadow 200ms, border-color 200ms;
    transition: box-shadow 200ms, border-color 200ms;
    white-space: nowrap; 
  }
  .us-form select option {
    text-shadow: none; 
  }

  .boxshadow .us-form select:not([size]):focus {
    outline: none;
    border-color: #66ccff;
    box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.4), 0 0 4px #66ccff; 
  }
  .boxshadow.no-touch .us-form select:not([size]):hover {
    box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.4), 0 0 0 3px #ffff88; 
  }
  
  .cssgradients .us-form select:not([size]) {
    border-color: #ececec;
    background-color: #ececec;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #ececec));
    background-image: -webkit-linear-gradient(top, white, #ececec);
    background-image: -moz-linear-gradient(top, white, #ececec);
    background-image: -ms-linear-gradient(top, white, #ececec);
    background-image: -o-linear-gradient(top, white, #ececec);
    background-image: linear-gradient(to bottom, white, #ececec); 
  }
  .cssgradients .us-form select:not([size]).focus {
    border-color: #66ccff; 
  }
  .cssgradients .us-form select:not([size]).error {
    border-color: #bf212e; 
  }
  .cssgradients .us-form.error select, .cssgradients .us-form .error select {
    border-color: #bf212e; 
  }
