.autor{
    padding: 1rem 0;
}

.autor-title{
    margin: 1rem 0;
}

.autor-wrapper{
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
}

.autor-img {
    grid-column: 1 / 2;
    grid-row: 1 / 1;
    max-width: 520px;
    margin-top: 10px;
    margin-left: 5px;
}

.autor-text {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
}

.autor-text p{
    margin: 0.8rem 0;
    opacity: 0.8;
    color: #ddfcb6;
}

.autor-text h2{
    text-align: center;
    text-decoration: underline;
    margin: 0.2rem 0;
    color: #ddfcb6;
}

@media screen and (min-width: 800px){
    .autor-content{
        grid-template-columns: repeat(2, 1fr);
    }
    .autor-title{
        margin-top: 0;
    }
    .autor-img img{
        width: 100%;
        margin-left: 5px;
    }
}

@media screen and (max-width: 800px){
    .autor-wrapper{
        grid-template-rows: auto auto;
    }

    .autor-img {
        margin-top: 10px;
        grid-column: 1 / 1;
        grid-row: 1 / 2;
    }

    .autor-text {
        grid-column: 1 / 1;
        grid-row: 2 / 2;
    }

    .autor-title{
        margin-top: 0;
    }
    .autor-img img{
        width: 100%;
        margin-left: 5px;
    }
}