.header-content{
    min-height: 75vh;
    flex-direction: column;
}
.header-title{
    font-size: 4.2rem;
}
.header-text{
    margin-top: -1.8rem;
    margin-bottom: 2.8rem;
    opacity: 0.8;
    max-width: 770px;
}

.wrapper{
    display: grid;
    grid-template-columns: [first] 25rem [carrusel] auto;
    gap: 1px;
    grid-template-rows: repeat(3, auto);
}

.buscador{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.extras{
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.amigs{
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}

.carrusel{
    height: 400px;
    width: auto;
    max-width: 1100px;
    grid-column: 2 / 3;
    grid-row: 1 / 4;
}

@media only screen and (max-width: 640px) {
    .wrapper{
        display: grid;
        grid-template-columns: [first] auto;
        gap: 1px;
        grid-template-rows: repeat(4, auto);
    }

    .buscador{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
    
    .extras{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    
    .amigos{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
    
    .carrusel{
        max-width: 640px;
        scale: 90%;
        grid-column: 1 / 1;
        grid-row: 4 / 5;
    }
}