.about{
    padding: 3rem 0;
}
.about-content{
    gap: 4rem;
}
.about-title{
    margin: 1rem 0;
}
.about-img img{
    max-width: 520px;
}
.about-text p{
    margin: 0.8rem 0;
    opacity: 0.8;
    color: #ddfcb6;
}

.about-text a{
    color: blue;
    background-color: greenyellow;
}

.about-text h2{
    margin: 1rem 0;
    color: #ddfcb6;
}

.about-text h5{
    margin: -2rem 0;
    color: #ddfcb6;
    text-align: left;
    padding-bottom: 1.5rem;
}

.vinul ul {
    list-style-type: disc;
    padding-left: 1.8rem;
    color: yellow;
    margin-left: 1.5rem;
}
.vinul p {
    padding: -4rem -3.1rem -4rem -3rem;
    margin-bottom: -3rem;
}

@media screen and (min-width: 800px){
    .about-title{
        margin-top: 0;
    }
    .autor-img img{
        margin-left: 0;
    }
}