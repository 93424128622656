.agradec{
    padding: 0.5rem 0;
}
.agradec-content{
    gap: 4rem;
}
.agradec-title{
    margin: 1rem 0;
}
.agradec-img img{
    max-width: 520px;
}
.agradec-text p{
    text-align: justify;
    margin: 1.8rem 0;
    opacity: 0.8;
    color: #ddfcb6;
}

.agradec-text a{
    color: blue;
    background-color: greenyellow;
}

.agradec-text h2{
    text-align: center;
    text-decoration: underline;
    margin: 1rem 0;
    color: #ddfcb6;
}

@media screen and (min-width: 800px){
    .agradec-title{
        margin-top: 0;
    }
    .agradec-img img{
        margin-left: 0;
    }
}