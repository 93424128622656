.form-wrapper {
    padding: 0.6rem;
    margin: 1.2rem 0;
    box-shadow: rgba(0, 50, 0, 0.9) 8px 8px 12px -3px;
    background-color:var(--yellow-green-color);
    border-radius: 10px;
}

.form-image p {
    padding-right: 0.5rem;

}
.image-preview {
    padding-left: 0.5rem;
    margin-left: 0.5rem;
}

.form-image input {
    padding-left: 0.5rem;
    margin-left: 0.5rem;
    max-width: 600px;
}

.title-form input {
    border-radius: 5px;
    width: 300px;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
}

.title-fecha input {
    border-radius: 5px;
    width: 60px;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
}

.description-form textarea {
    width: 600px;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
}

.title-form select {
    padding-left: 0.5rem;
    margin-left: 0.5rem;
    max-width: 600px;
}

.show {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

.hide {
    display: none;
}

.bton-form {
    display: flex;
   flex-direction: column;
 /*   flex-wrap: wrap;*/
align-items: flex-end;
}

.bton-agregar{
    padding: 0.5rem;
   /* margin: 0.4rem 0;*/
   /* margin-right: 0;
    margin-left: auto;*/
    align-items: flex-end;
    box-shadow: rgba(0, 50, 0, 0.9) 8px 8px 12px -3px;
    color: var(--special-green-color);
    background-color: green;
    border-radius: 10px;
}

.errorform {
    font-size: 14px;
    font-weight: 400;
    color: red;
}

.border-error {
    border: 2px solid red;
  }

  .book {
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 3rem 2rem;
    border-radius: 14px;
    background-color: var(--yellow-green-color);
    box-shadow: rgba(0, 50, 0, 0.7) 0px 15px 25px 0px;
    transition: var(--transition);
  }

  .white-bg {
    background-color: white;
    padding: 3px;
    width: 30rem;
    border-radius: 5px;
  }
