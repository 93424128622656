.gridedit-content{
    min-height: 75vh;
    flex-direction: column;
}
.gridedit-title{
    font-size: 2.2rem;
    color: yellow;
}
.gridedit-text{
    margin-top: 1.8rem;
    margin-bottom: 2.8rem;
    opacity: 0.8;
    max-width: 770px;
}

.gridedit-wrapper{
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 10px;
  /*  grid-template-rows: repeat(1, auto);*/
}

.busc-edit{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.selec-edit{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.selec-revist-edit{
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}

.edicion-edit{
    width: 100%;
}

@media only screen and (max-width: 640px) {
    .wrapper{
        display: grid;
        grid-template-columns: [first] auto;
        gap: 10px;
        grid-template-rows: repeat(4, auto);
    }

    .buscedit{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
    
    .selecedit{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    .selecrevistedit{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
   
    .edicionedit{
        grid-column: 1 / 1;
        grid-row: 4 / 5;
    }
}